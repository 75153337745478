import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,
    usuariosDoSetor: [],
  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },

  },
  actions: {
    fetchComentarios({ commit }, itemData) {
      commit('alteraLoading', true)
      return new Promise(resolve => {
        axios
          .get(`/projetos/${itemData.id}/comentarios`)
          .then(response => {
            resolve(response.data)

            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    getUsers({ commit }, itemData) {
      return new Promise(resolve => {
        axios
          .get(`/users?setor=&perPage=1000`)
          .then(response => {
            resolve(response.data)

          })
          .catch(error => console.log(error))
      })
    },

    store(ctx, itemData) {
      const formData = new FormData()

      formData.append('comentario', itemData.comentario)

      return new Promise((resolve, reject) => {
        axios
          .post(`/projetos/${itemData.id}/comentarios`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    responderComentario(cxt, {
      id,
      comentario
    }) {
      console.log('comentario', comentario, id)
      return new Promise((resolve, reject) => {
        axios
          .post(`/comentarios/${id}/reply`, { comentario: comentario })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete(ctx, item) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/projetos/${item.projeto}/comentarios/${item.id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },
  },
}
